.cover {
  @apply relative h-auto;
  padding-top: 100%;
  background: url(/img/board-cover.png) no-repeat;
  background-size: contain;
}

.coverItem {
  @apply absolute;
  width: 83.5%;
  height: 83.5%;
  left: 2%;
  border-radius: 4.5%;
  top: 2%;
}
