.statusEnter {
  opacity: 0;

  .statusMessage {
    opacity: 0;
    transform: scale(0.85);
  }
}

.statusEnterActive {
  opacity: 1;
  transition: opacity 150ms ease-out;

  .statusMessage {
    opacity: 1;
    transform: scale(1) translateY(0);
    transition: all 150ms ease-out;
  }
}

.statusExit {
  opacity: 1;

  .statusMessage {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.statusExitActive {
  opacity: 0;
  transition: opacity 150ms ease-out;

  .statusMessage {
    opacity: 0;
    transform: scale(0.85);
    transition: all 150ms ease-out;
  }
}
