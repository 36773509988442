.input {
  &:checked + label .toggle {
    @apply bg-new-blue-100;

    &:active {
      @apply bg-new-blue-100/60;
    }

    & span {
      transform: translateX(100%);
    }
  }
  
  &:disabled + label .toggle {
    @apply bg-new-gray-200 pointer-events-none;
  }
}

.toggle {
  @apply bg-new-blue-100/60 transition duration-150 ease-out;

  &:active {
    @apply bg-new-blue-100;
  }

  & span {
    transition: all 150ms ease-out;
  }
}
