.modalEnter {
  opacity: 0;
  transform: scale(1.05);
}

.modalEnterActive {
  opacity: 1;
  transform: scale(1);
  transition: all 200ms ease-out;
}

.modalExit {
  opacity: 1;
  transform: scale(1);
}

.modalExitActive {
  opacity: 0;
  transform: scale(1.05);
  transition: all 200ms ease-out;
}
