.loading {
  animation: loading-anim 2s ease infinite;
}

@keyframes loading-anim {
  0% {
    opacity: 1;
  }

  60% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
