.popupEnter {
  opacity: 0;
  transform: scale(0.85, 0.9);
}

.popupEnterActive {
  opacity: 1;
  transform: scale(1);
}

.popupExit {
  opacity: 1;
  transform: scale(1);
}

.popupExitActive {
  opacity: 0;
  transform: scale(0.85, 0.9);
}

.popupEnterActive {
  transition: all 150ms ease-out;
}

.popupExitActive {
  transition: all 150ms ease-in;
}
